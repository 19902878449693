@import "./libs/bootstrap.css";
@import "./libs/materialadmin.css";
@import "./libs/font-awesome/fontawesome.less"; // v5.3.1
@import "./libs/font-awesome/regular.less";
@import "./libs/font-awesome/solid.less";
@import "./libs/font-awesome/light.less";
@import "./libs/font-awesome/brands.less";
@import "./themes/default/theme.less";
@import "./themes/modern/theme.less";
@import "./common.less";
@import "./rtl.less";
